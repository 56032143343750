/** @jsx jsx */
import {jsx} from 'theme-ui'
import LicenceAgreementMDX from "../sections/licence-agreement.mdx"
import Layout from "../components/layout"

const LicenceAgreement = () => (
    <Layout>
      <LicenceAgreementMDX/>
    </Layout>
)

export default LicenceAgreement
