import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`End User License Agreement`}</h3>
    <p>{`This End User License Agreement (“EULA” or “Agreement”) is a legal agreement between you and PristineBytes, Daniel Kleinert, Sternbergstr. 15, Karlsruhe 76131, Germany (“Company” or “PristineBytes”). PristineBytes is the legal owner of software applications (“Products“) that interoperate with applicable Atlassian Products and you purchase or evaluate from Atlassian Marketplace. This Agreement provides the terms and conditions by which you may use the Products. If you don’t like what you see here, do not install or use the Products. By downloading or using it (for Cloud Apps), however, you agree to use the Products consistent with the terms of this EULA. The terms of any customer service agreement, purchase order, order, confirmation or similar document provided by customer will have no effect and will not be considered agreed to by PristineBytes.`}</p>
    <p>{`When you are accepting this Agreement on behalf of your employer or another company or organization, you represent and warrant that you have full authority to act for and to bind that legal entity to this Agreement; the terms “you” and “your” will refer to that legal entity.`}</p>
    <h5>{`1. GENERAL`}</h5>
    <p>{`Products can be used only on devices that also have an Atlassian Product installed (or through which an Atlassian Product is otherwise accessed) and meet certain other technical requirements described in the Marketplace Guidelines of Atlassian (`}<a parentName="p" {...{
        "href": "https://developer.atlassian.com/display/MARKET"
      }}>{`https://developer.atlassian.com/display/MARKET`}</a>{`), as amended from time to time by Atlassian.`}</p>
    <h5>{`2. PRODUCTS`}</h5>
    <p><strong parentName="p">{`2.1`}</strong>{` “Server App” means downloadable App that you obtain from PristineBytes or its authorized reseller hosted on your server. Atlassian may also designate them as “Data Center” deployments or apps. To keep the Server App updated you have to download the newest version. There will be no automatic update as the Server App is not connected to any cloud structure.`}</p>
    <p><strong parentName="p">{`2.2`}</strong>{` “Cloud App” means App run on Atlassian’s servers and provided to you as cloud-based (software-as-service) solution for the applicable subscription term. The Cloud Apps are subject to modification from time to time at PristineBytes’s sole discretion, for any purpose deemed appropriate by PristineBytes.`}</p>
    <p><strong parentName="p">{`2.3`}</strong>{` We may offer free evaluation and/or other limited versions of our products so you can preview the products before you purchase the full version (“Evaluation Products“). If the Product is only provided for evaluation purposes, your rights are limited to an evaluation license which permits you to use, download, install, and/or operate the Evaluation Product for a limited period of time (“Evaluation Period“), and it will only be accessible by a limited number of temporary users, in each case as determined by PristineBytes. On the expiry of the Evaluation Period, the Evaluation Product will cease to function and you will remove and delete all copies of such Product in your possession or control. If you do not do so, PristineBytes is allowed to revoke your license and lock your access.`}</p>
    <p><strong parentName="p">{`2.4`}</strong>{` Only authorized users may access and use Product. Some Products may allow you to designate different types of authorized users, in which case functionality may vary according to the type of authorized user. You are responsible for compliance with this Agreement by all authorized users. All use of Product by you and your authorized users must be within the scope of use, according to documentation and solely for the benefit of you or your affiliates.`}</p>
    <p><strong parentName="p">{`2.5`}</strong>{` If Product requires access credentials (authorized users’ IDs and passwords) you must ensure that all authorized users keep their access credentials for Product strictly confidential and not share such information with any unauthorized person. User IDs are granted to individual, named persons and may not be shared. You and authorized users may not convey, transfer or otherwise disseminate access credentials or their parts to any other person, entity or organization. You accept responsibility for the confidentiality and use of access credentials that you may receive for your authorized users’ access to and use of Product. You accept responsibility for all time used and all activity that occurs through or under your access credentials. You agree to notify PristineBytes immediately if you become aware of any loss, theft or unauthorized use of access credentials.`}</p>
    <p><strong parentName="p">{`2.6`}</strong>{` PristineBytes reserves the right, at any time and at its sole discretion:`}</p>
    <ul>
      <li parentName="ul">{`To modify or revise this Agreement.`}</li>
      <li parentName="ul">{`To update or modify documentation.`}</li>
      <li parentName="ul">{`To make changes, update or discontinue Products or any feature or functionality thereof at any time.`}</li>
      <li parentName="ul">{`To terminate or restrict access to Product.`}</li>
    </ul>
    <p>{`PristineBytes will ask for your consent for substantial change that is not in an improvement, maintenance of the function of the product etc. as follows:`}</p>
    <ul>
      <li parentName="ul">{`By sending an email to the contact you designate;`}</li>
      <li parentName="ul">{`In Product itself. `}</li>
    </ul>
    <p>{`You may be required to click through the updated Agreement to show your acceptance (in Product) or accept it by remaining silent (email); if you do not disagree with these changes in writing or by email to PristineBytes within 3 weeks after receipt of the e-mail with the changes. If you do not agree to the updated Agreement after it becomes effective, you shall discontinue use of App immediately.`}</p>
    <h5>{`3. GRANT OF LICENSE`}</h5>
    <p><strong parentName="p">{`3.1`}</strong>{` PristineBytes own all rights, titles and interests, including all worldwide intellectual property rights in Product and the trademarks, service marks and logos contained therein, and the Products are licensed to you directly by PristineBytes, not sold, irrespective of the use of terms such as “purchase” or “sale”.`}</p>
    <p><strong parentName="p">{`3.2`}</strong>{` Subject to the terms and conditions of this Agreement and provided you have paid the applicable fee to Atlassian and/or its authorized partners (except for Evaluation Period and for free Products), you are granted a limited, worldwide, non-exclusive, non-transferable, non-assignable, non-sublicensable and revocable license to (i) download and install one production instance (except for evaluation period) of the Products on your Systems (for Server App), (ii) access (for Cloud App), and (iii) use Products on your Systems for single instance of your host application (Confluence, Jira) during the term of the Agreement or the applicable subscription term:`}</p>
    <ul>
      <li parentName="ul">{`for paid Products – according to (a) the scope of use, (b) the license terms as provided in the “Pricing” Section of Product details page, and (c) documentation (which means any user manuals, FAQ or support pages, information contained on Product details page, and other documentation related to Product provided by PristineBytes in electronic or online form);`}</li>
      <li parentName="ul">{`for free Products – according to the terms specified in documentation (as above).`}</li>
    </ul>
    <p><strong parentName="p">{`3.3`}</strong>{` You may make and use one copy of Server App for disaster recovery or business resumption purposes. There may be storage limits associated with a particular Cloud App. Such limits, if imposed, will be described on Product detail page or in other documentation (as above).`}</p>
    <p><strong parentName="p">{`3.4`}</strong>{` License is granted subject to the condition that you must ensure the maximum number of authorized users that are able to access and use Product concurrently is less or equal to the number of users for which the necessary fees have been paid to Atlassian and/or its authorized partners. You may purchase additional user licenses at any time on payment of the appropriate fees to Atlassian and/or its authorized partners. The maximum number of authorized users will be determined by the licence tier you have chosen when ordering Product.`}</p>
    <p><strong parentName="p">{`3.5`}</strong>{` You acknowledge that Product will only function if its licence tier matches the tier of the host application (Confluence, Jira).`}</p>
    <p><strong parentName="p">{`3.6`}</strong>{` Products may be connected, interoperate or work with, and/or utilize third-party services. You recognize and acknowledge that: (i) Use of any third-party services and information will be governed by the applicable license agreements or terms, if any, with such third party. You shall comply with the terms of these agreements. PristineBytes is not responsible for these services; (ii) PristineBytes conducts no investigation or review of any of such third-party services, and does not recommend, endorse, approve or disapprove of any of them.`}</p>
    <h5>{`4. RESTRICTIONS`}</h5>
    <p>{`You may not: (i) reverse engineer, decompile or disassemble Product or otherwise seek to obtain or derive the source code, underlying ideas or algorithms, except as permitted by law; (ii) adapt, alter, modify, translate, make any attempt to create derivative works of/from Product; (iii) defeat, bypass, disable, interfere with or otherwise circumvent any license key mechanism in Product or otherwise circumvent mechanisms in Products intended to limit your use; (iv) attempt to access or use Product other than by using authorized users’ IDs and passwords required to access functionality of Product (if any); (v) remove or alter any trademark, logo, copyright or other proprietary notices, legends, symbols or labels in Product; (vi) redistribute, encumber, sell, rent, lease, sublicense, assign, or otherwise transfer Product or any rights or interest therein; (vii) use Product as part of a service provider, software-as-a-service, display, perform, or publish or otherwise permit third parties to benefit from the use or functionality of Product, unless it is not expressly permitted by this Agreement.; (viii) use Product in any manner not authorized by this Agreement.`}</p>
    <h5>{`5. MAINTENANCE`}</h5>
    <p><strong parentName="p">{`5.1`}</strong>{` The purchase price of a Server App includes maintenance (version updates and support). Maintenance is valid for a prepaid period of time as agreed by you and Atlassian and/or its authorized partners. You are eligible for maintenance of Cloud Apps as long as your subscription is active.`}</p>
    <p><strong parentName="p">{`5.2`}</strong>{` The pricing details and terms of maintenance renewal are specified in the “Pricing” section of the Product details page.`}</p>
    <p><strong parentName="p">{`5.3`}</strong>{` PristineBytes will make updates or upgrades available for Product, if and when available. PristineBytes does not undertake to provide maintenance for free Products. PristineBytes may, at its sole discretion and on its own terms provide maintenance for free Products.`}</p>
    <p><strong parentName="p">{`5.4`}</strong>{` Support for Product consists of online documentation and support via email. Support is available in English. For the avoidance of doubt support and maintenance do not include any custom software development or implementation of custom features for Products or any assistance with the host application or any other third-party services or products, their updating, maintenance or support.`}</p>
    <h5>{`6. FEES AND PAYMENT`}</h5>
    <p><strong parentName="p">{`6.1`}</strong>{` Pricing terms and conditions are set out in the “Pricing” section of Product details page and are subject to change. Cloud Apps are subscription-based.`}</p>
    <p><strong parentName="p">{`6.2`}</strong>{` Atlassian and/or its authorized partners will deliver the applicable license keys (in the case of Server App) or login instructions (in the case of Cloud App) to the email address(es) you specified to Atlassian and/or its authorized partners. All deliveries will be electronic. You are responsible for installation of Product and its compatibility with your systems, other hardware, software and services.`}</p>
    <p><strong parentName="p">{`6.3`}</strong>{` Any and all payments, delivery, renewals, subscriptions and refunds are handled by and managed through Atlassian and/or its authorized partners. PristineBytes is not involved in these matters, is not responsible for the processing of payments, delivery, renewals, subscriptions or refunds, and shall not be liable for any matter in connection therewith. Please, refer to the “Pricing” section of Product detail page and to a relevant Atlassian Marketplace T&C for more information about pricing, delivery, billing, subscriptions, renewals and payment terms.`}</p>
    <h5>{`7. TERMINATION`}</h5>
    <p><strong parentName="p">{`7.1`}</strong>{` This Agreement shall commence upon first installation, download, subscription to or use of Product by you, whether Product is provided for evaluation, as a fully licensed version, or in any other form.`}</p>
    <p><strong parentName="p">{`7.2`}</strong>{` Unless earlier terminated as set forth herein or terminated due to expiration of evaluation period, this Agreement is effective for the term specified in the “Pricing” section of Product Details Page. Your license will end upon any termination of this Agreement, even if it is identified as “perpetual” or if no expiration date is specified when you ordered Product.`}</p>
    <p><strong parentName="p">{`7.3`}</strong>{` You may terminate this Agreement at any time, for any reason or no reason, but this does not release you from any obligations that may have already arisen (especially payment obligations).`}</p>
    <p><strong parentName="p">{`7.4`}</strong>{` Without limiting other remedies, PristineBytes may suspend or terminate this Agreement with you, or may terminate or suspend your use of Product at any time and with no liability to you if:`}</p>
    <ul>
      <li parentName="ul">{`You violate any term of this Agreement.`}</li>
      <li parentName="ul">{`You infringe proprietary rights, rights of privacy, or intellectual property rights of any person, business or organization.`}</li>
      <li parentName="ul">{`You engaged in other actions relating to or in the course of using Product that may be illegal or cause liability, harm, abuse or disruption for you, other users, PristineBytes, any other third parties or for Product.`}</li>
      <li parentName="ul">{`We have not received our corresponding payment for Product from Atlassian and/or its authorized partners.`}</li>
      <li parentName="ul">{`You evaluate the product more than 60 days in a year (without payment)`}</li>
      <li parentName="ul">{`It is required by law.`}</li>
      <li parentName="ul">{`PristineBytes ceases offering or discontinued Product.`}</li>
      <li parentName="ul">{`If adequate, PristineBytes will inform you in advance of the violation and set a deadline for its elimination.`}</li>
    </ul>
    <p><strong parentName="p">{`7.5`}</strong>{` We may terminate your right to use free Apps at any time and for any reason in our sole discretion, without liability to you.`}</p>
    <p><strong parentName="p">{`7.6`}</strong>{` Upon termination, all license grants end and you must destroy and permanently erase all copies of Product and PristineBytes’s confidential information, and cease all use. Whatever the cause of termination is, you will not receive any credits or refunds for any license or maintenance fees which you may have paid in advance (including fees for a perpetual license), except as may otherwise be provided by Atlassian Marketplace T&C. You agree and acknowledge that Atlassian and/or its authorized partners are responsible for refunds (if any) and you will not request any refunds from PristineBytes.`}</p>
    <p><strong parentName="p">{`7.7`}</strong>{` The following provisions will survive any termination or expiration of this Agreement: Ownership, License Restrictions, Third Party Software and Services, Promotional Materials, Your Feedback, Confidentiality, Limitation of Liability, Miscellaneous.`}</p>
    <h5>{`8. LIABILITY`}</h5>
    <p><strong parentName="p">{`8.1`}</strong>{` Either Party’s liability shall be unlimited for: loss or damage caused intentionally or by the gross negligence of the Party itself, its directors or its officers or intentionally or by the gross negligence by its other vicarious agents; loss or damage from acts causing death, body injury or illness, or from the assumption of a guarantee or a procurement risk, or under the German Product Liability Act.`}</p>
    <p><strong parentName="p">{`8.2`}</strong>{` Either Party’s liability for: the slight negligence of itself, its directors, officers or other vicarious agents in case the damage results from a breach of a material contractual obligation shall in no case exceed the aggregate of 12 months fees paid by you and/or due from immediately preceding the event giving rise to such claim.`}</p>
    <p><strong parentName="p">{`8.3`}</strong>{` Subject to Sections 8.1 and 8.2 above, any other liability of one of the parties is excluded.`}</p>
    <p><strong parentName="p">{`8.4`}</strong>{` Where a party’s liability is limited in accordance with Section 8.2, it will not be liable for lost profits or any other indirect damages.`}</p>
    <p><strong parentName="p">{`8.5`}</strong>{` Each party must use reasonable endeavors to mitigate any loss or damage it sustains or incurs under or in connection with this Agreement.`}</p>
    <h5>{`9. INDEMNIFICATION`}</h5>
    <p>{`You agree to indemnify and hold Codefourtynine and its subsidiaries, affiliates, officers, agents, and employees harmless from any claims by third parties, and any related damages, losses or costs (including reasonable attorney fees and costs), arising out of content you submit to or publish on this website, your use of this website or any Products, your violation of this Agreement or your violation of any rights of a third party.`}</p>
    <h5>{`10. DATA`}</h5>
    <p><strong parentName="p">{`10.1`}</strong>{` You agree that PristineBytes may collect and use technical data and related information, including without limitation, technical information relating to your device, system, Product, that is gathered periodically to facilitate the provision of software updates, product support, marketing efforts and other services to you related to the Product. PristineBytes may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technology to you.`}</p>
    <p><strong parentName="p">{`10.2`}</strong>{` If you evaluate and/or install Products those applications may transmit your data outside of Atlassian’s systems during normal use, and PristineBytes may be able to obtain access to your data in Atlassian Products through the applicable Atlassian Product’s application programming interface (API). Such access may result in the disclosure, modification or deletion of your data by PristineBytes or the PristineBytes Products. Further PristineBytes may collect and use data pertaining to your configuration and/or use of the PristineBytes Products. Any information that PristineBytes collects from you or your device will be subject to our Privacy Policy (`}<a parentName="p" {...{
        "href": "https://www.pristinebytes.com/privacy-policy"
      }}>{`https://www.pristinebytes.com/privacy-policy`}</a>{`).`}</p>
    <h5>{`11. CONFIDENTIALITY`}</h5>
    <p><strong parentName="p">{`11.1`}</strong>{` PristineBytes does not require from you to provide PristineBytes with any of your confidential or non-public information.`}</p>
    <p><strong parentName="p">{`11.2`}</strong>{` Except as otherwise set forth in this Agreement, each party agrees that all code, inventions, know-how, business, technical and financial information disclosed to such party (“Receiving Party”) by the disclosing party (“Disclosing Party“) constitute the confidential property of the Disclosing Party (“Confidential Information“), provided that it is clearly identified as confidential at the time of disclosure and is accepted by the Receiving Party as such. In case the Receiving Party believes the Disclosing Party has shared with the Receiving Party information, marked as confidential, but which the Receiving Party does not need or has not requested, the Receiving Party will immediately return to the Disclosing Party and/or destroy all records of such information. Notwithstanding the above any PristineBytes’s intellectual property and technology shall be deemed Confidential Information of PristineBytes without any marking or further designation.`}</p>
    <p><strong parentName="p">{`11.3`}</strong>{` Except as expressly authorized herein, the Receiving Party will hold in confidence and not use or disclose any Confidential Information.`}</p>
    <p><strong parentName="p">{`11.4`}</strong>{` The Receiving Party’s nondisclosure obligation shall not apply to information which the Receiving Party can document: (i) was rightfully in its possession or known to it prior to receipt of the Confidential Information; (ii) is or has become public knowledge through no fault of the Receiving Party; (iii) is rightfully obtained by the Receiving Party from a third party without breach of any confidentiality obligation; or (iv) is independently developed by employees of the Receiving Party who had no access to such information. The Receiving Party may also disclose Confidential Information if so required pursuant to a regulation, law or court order (but only to the minimum extent required to comply with such regulation or order and with advance notice to the Disclosing Party).`}</p>
    <h5>{`12. MISCELLANEOUS`}</h5>
    <p><strong parentName="p">{`12.1`}</strong>{` You agree that the laws of the Republic of Germany govern this Agreement, its subject matter, any action related to this Agreement, and any claim or dispute that may arise, without regard to the conflict of laws rules, and that the United Nations Convention on Contracts for the International Sale of Goods shall have no applicability. You further agree that any disputes or claims related to this Agreement will be resolved by a state court located in the Republic of Germany.`}</p>
    <p><strong parentName="p">{`12.2`}</strong>{` If any term of this EULA is held invalid, unenforceable, or void by a court of competent jurisdiction, such term will be enforced to the maximum extent permissible, such holding will not affect the remaining terms, and the invalid, unenforceable, or void term will be deemed amended or replaced by a valid, legal, and enforceable term that matches the intent of the original language as closely as possible.`}</p>
    <p><strong parentName="p">{`12.3`}</strong>{` You grant PristineBytes the right to mention you as his Customer in promotional materials or at PristineBytes’s website. You can terminate this license at any time sending an email request to `}<a parentName="p" {...{
        "href": "mailto:support@pristinebytes.com"
      }}>{`support@pristinebytes.com`}</a>{`.`}</p>
    <p><strong parentName="p">{`12.4`}</strong>{` If you choose to communicate to PristineBytes any feedback, ideas or suggestions for improvement of Products, you grant PristineBytes free-of-charge, irrevocable, non-exclusive, transferable right to use, modify, share, distribute and communicate such feedback for any and all commercial or non-commercial purposes, without charge and free of any obligation of attribution. You warrant that any such feedback you communicated to PristineBytes is not subject to any license or any other third party right. No such feedback will be considered your confidential information.`}</p>
    <p><strong parentName="p">{`12.5`}</strong>{` For contractual purposes, you:`}</p>
    <ul>
      <li parentName="ul">{`Consent to receive communications from us in an electronic form via the email address you have submitted; and`}</li>
      <li parentName="ul">{`Agree that this Agreement and all other agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications would satisfy if it were in writing.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      